import {
  Button,
  HStack,
  Image,
  ListItem,
  OrderedList,
  Stack,
  Text,
  useClipboard,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import {
  BookingStatusEnum,
  updateBookingStatus,
  useGetBookingByID,
} from 'src/api/services/booking';
import { chargePix, useGetBookingInstallments } from 'src/api/services/charge';
import { Loading } from 'src/components/Loading';
import usePHToast from 'src/hooks/useToast';
import { PixData } from 'src/pages/tickets/payment';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { formatCurrency, hashData } from 'src/utils/format';
import Cookies from 'universal-cookie';

interface PixFormProps {
  qrCode?: PixData;
  bookingID?: string;
  idempotencyKey?: string;
  handleIdempotencyKey: (key?: string) => void;
  handlePixGenerated: (data?: PixData) => void;
  onErrorOpen: () => void;
}

export default function PixForm({
  qrCode,
  bookingID,
  idempotencyKey,
  handleIdempotencyKey,
  handlePixGenerated,
  onErrorOpen,
}: PixFormProps) {
  const toast = usePHToast();
  const { user } = useAppSelector((state) => state.user);
  const { data: bookingData } = useGetBookingByID(bookingID);

  const { onCopy, hasCopied } = useClipboard(qrCode?.qrCodeData ?? '');

  const [loading, setLoading] = useState(false);

  const { data: installmentsOptions, isLoading } = useGetBookingInstallments(
    bookingID,
    3
  );

  const handleGeneratePix = async () => {
    try {
      if (!user) throw new Error('É necessário estar logado para comprar.');
      if (!bookingData)
        throw new Error('É necessário ter uma reserva comprar.');
      setLoading(true);

      const cookies = new Cookies();

      const pixelData = {
        eventID: crypto.randomUUID(),
        event_source_url: window.location.href,
        action_source: 'website',
        event_name: 'Purchase',
        client_user_agent: navigator.userAgent,
        event_time: Math.floor(Date.now() / 1000),
        first_name: await hashData(user?.name),
        last_name: await hashData(user?.lastName),
        email: await hashData(user?.email),
        phone: await hashData(user?.phone),
        zip_code: await hashData(user?.zipCode),
        currency: 'BRL',
        value: bookingData.total,
        click_id: cookies.get('_fbc'),
        browser_id: cookies.get('_fbp'),
      };

      const data = {
        userId: user.userID,
        bookingId: bookingData.bookingID,
        amount: bookingData.total,
        idempotencyKey,
        pixelData,
      };

      const response = await chargePix(data);
      if (response.result.sucess) {
        const { qrCodeImageUrl, qrCodeData, pixGenerationTime } =
          response.result;
        if (qrCodeImageUrl && qrCodeData) {
          await updateBookingStatus(
            bookingData.bookingID,
            BookingStatusEnum.PROCESSANDO_PAGAMENTO,
            {}
          );
          handlePixGenerated({
            qrCodeImageUrl,
            qrCodeData,
            pixGenerationTime,
            pixelData,
          });
          return;
        }
      }
      handleIdempotencyKey(response.result.idempotencyKey);
      throw new Error(response.errors);
    } catch (e: any) {
      handlePixGenerated(undefined);
      onErrorOpen();
      toast({ status: 'error', title: e?.message });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack
      w="full"
      direction={['column', 'row']}
      spacing={{ base: 12, md: 4 }}
      px={{ base: 0, md: 4 }}
      py={12}
      align="center"
      justify={'space-between'}
    >
      <Stack direction={'column'} justify={'flex-end'} px={{ base: 6, md: 12 }}>
        <Text variant="text" fontWeight="bold">
          COMO PAGAR?
        </Text>
        <OrderedList>
          <ListItem color="white">
            Abra o app do seu banco ou instituição financeira e selecione a
            opção Pix;
          </ListItem>
          <ListItem color="white">
            Cole o código ou escaneie o QR Code;
          </ListItem>
          <ListItem color="white">
            Confira os dados e confirme seu pagamento pelo app do banco.
          </ListItem>
        </OrderedList>
      </Stack>

      <Stack direction="column" align="center" w={'full'}>
        <HStack>
          {isLoading ? (
            <Loading />
          ) : (
            <Text color="white">{`Valor de R$${installmentsOptions?.installments[0].installmentTotal}`}</Text>
          )}
          {/* <Text color='green'>(5% de desconto)</Text> */}
        </HStack>
        {!qrCode ? (
          <Button
            w={{ base: 'full', md: 'unset' }}
            variant="pophausOutline"
            onClick={handleGeneratePix}
            isLoading={loading}
          >
            GERAR CÓDIGO PIX
          </Button>
        ) : (
          <>
            <Image
              maxW="220px"
              bg="white"
              objectFit="cover"
              px={2}
              py={2}
              src={qrCode.qrCodeImageUrl}
              fallbackSrc="https://via.placeholder.com/220"
            />
            <Button
              maxW="220px"
              variant="pophausOutline"
              onClick={onCopy}
              isLoading={loading}
            >
              {hasCopied ? 'Copiado!' : 'Copiar código Pix'}
            </Button>
          </>
        )}
      </Stack>
    </Stack>
  );
}
