export const formatCurrency = (value: number) => {
  return Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value);
};

export function truncateName(name: string, maxLength: number) {
  if (name.length <= maxLength) {
    return name;
  }

  const truncatedName = name.substring(0, maxLength - 3) + '...';
  return truncatedName;
}

export const hashData = async (text: string | undefined) => {
  if (!text) return null;
  const encoder = new TextEncoder();
  const data = encoder.encode(text);
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');
  return hashHex;
};
