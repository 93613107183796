import React, { useState } from 'react';
import { Button, Stack, useDisclosure, Text } from '@chakra-ui/react';
import { UseFormReturn, useForm } from 'react-hook-form';
import { AcceptedCards } from 'src/components/AcceptedCards';
import Form from 'src/components/Form/Form';
import { FormInput } from 'src/components/Form/Input';
import { FormSelect } from 'src/components/Form/Select';
import { useAppSelector } from 'src/redux/hooks';
import { useGetBookingByID } from 'src/api/services/booking';
import {
  chargeCardOneShoot,
  useGetBookingInstallments,
} from 'src/api/services/charge';
import {
  ChargeCardOneShootRequest,
  InstallmentsResult,
} from 'src/api/interfaces/charge';
import usePHToast from 'src/hooks/useToast';
import Cookies from 'universal-cookie';
import { hashData } from 'src/utils/format';

interface CardFormProps {
  paymentMethod: 'credit' | 'debit' | 'pix' | 'local' | 'courtesy';
  cardForm: UseFormReturn<CardFormValues, any>;
  idempotencyKey?: string;
  bookingID?: string;
  onOpen: () => void;
  onErrorOpen: () => void;
  triggerPurchaseEvent: () => void;
  handleIdempotencyKey: (key?: string) => void;
}

export interface CardFormValues {
  cardHolderName: string;
  cardNumber: string;
  month: number;
  year: number;
  cardCvv: string;
  installments: number;
}

export default function CardForm({
  paymentMethod,
  idempotencyKey,
  bookingID,
  onOpen,
  onErrorOpen,
  handleIdempotencyKey,
  triggerPurchaseEvent,
}: CardFormProps) {
  const toast = usePHToast();
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useForm<CardFormValues>();

  const { user } = useAppSelector((state) => state.user);
  const { data: bookingData } = useGetBookingByID(bookingID);

  const getPaymentMethodID = (
    method: 'credit' | 'debit' | 'pix' | 'local' | 'courtesy'
  ) => {
    switch (method) {
      case 'credit':
        return 1;
      case 'debit':
        return 2;
    }
  };
  const { data: installmentsOptions, isLoading } = useGetBookingInstallments(
    bookingID,
    getPaymentMethodID(paymentMethod)
  );

  const [loading, setLoading] = useState(false);

  const onSubmit = async (data: CardFormValues) => {
    try {
      const { installments, month, year, ...cardInfo } = data;
      if (!user) throw new Error('É necessário estar logado para comprar.');
      if (!bookingData)
        throw new Error('É necessário ter uma reserva para comprar.');
      setLoading(true);

      const cookies = new Cookies();

      const eventID = crypto.randomUUID();

      const pixelData = {
        event_source_url: window.location.href,
        action_source: 'website',
        event_name: 'Purchase',
        client_user_agent: navigator.userAgent,
        event_time: Math.floor(Date.now() / 1000),
        first_name: await hashData(user?.name),
        last_name: await hashData(user?.lastName),
        email: await hashData(user?.email),
        phone: await hashData(user?.phone),
        zip_code: await hashData(user?.zipCode),
        currency: 'BRL',
        value: bookingData.total,
        click_id: cookies.get('_fbc'),
        browser_id: cookies.get('_fbp'),
      };

      const request: ChargeCardOneShootRequest = {
        userId: user.userID,
        name: user.name,
        email: user.email,
        bookingId: bookingData.bookingID,
        amount: bookingData.total,
        paymentType: paymentMethod,
        installments: paymentMethod === 'debit' ? 1 : installments,
        card: {
          ...cardInfo,
          cardExpirationDate: `${data.month}/${data.year}`,
        },
        idempotencyKey,
        pixelData: { ...pixelData, eventID },
      };
      const response = await chargeCardOneShoot(request);
      if (response.result.sucess) {
        if (window.fbq) {
          window.fbq('track', 'Purchase', pixelData, { eventID });
        }

        triggerPurchaseEvent();
        onOpen();
      } else {
        handleIdempotencyKey(response.result.idempotencyKey);
        throw new Error(response.errors);
      }
    } catch (e: any) {
      onErrorOpen();
      toast({ status: 'error', title: e?.message });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction={'column'} spacing={4} px={4} py={4}>
        <Stack direction={['column', 'row']}>
          <FormInput
            rhfRegister={{
              ...register('cardHolderName', {
                required: 'Campo obrigatório',
              }),
            }}
            errorState={errors.cardHolderName}
            label={'NOME IMPRESSO NO CARTÃO'}
            inputProps={{ placeholder: 'DIGITE O NOME IMPRESSO EM SEU CARTÃO' }}
          />
          <FormInput
            rhfRegister={{ ...register('cardNumber') }}
            errorState={errors.cardNumber}
            label={'NÚMERO DO CARTÃO'}
            inputProps={{ placeholder: '------------------', maxLength: 16 }}
          />
        </Stack>
        <Stack direction={['column', 'row']}>
          <FormInput
            rhfRegister={{
              ...register('month', {
                required: 'Campo obrigatório',
              }),
            }}
            errorState={errors.month}
            label={'MÊS'}
            inputProps={{ placeholder: '**', maxLength: 2 }}
          />
          <FormInput
            rhfRegister={{ ...register('year') }}
            errorState={errors.year}
            label={'ANO'}
            inputProps={{ placeholder: '****', maxLength: 4 }}
          />
          <FormInput
            rhfRegister={{ ...register('cardCvv') }}
            errorState={errors.cardCvv}
            label={'CVV'}
            inputProps={{ placeholder: '***', maxLength: 3 }}
          />
          {paymentMethod === 'credit' && (
            <FormSelect
              rhfRegister={{
                ...register('installments', {
                  required: 'Campo obrigatório',
                }),
              }}
              label={'PARCELAS'}
              errorState={errors.installments}
              selectProps={{ placeholder: 'SELECIONE AS PARCELAS' }}
              disabled={isLoading}
            >
              {installmentsOptions?.installments.map((o, i) => (
                <option key={i} value={o.installmentNumber}>
                  {o.installmentNumber <= 5
                    ? `${
                        o.installmentNumber
                      }x ${o.installmentTotal.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })} (Sem Juros)`
                    : `${
                        o.installmentNumber
                      }x ${o.installmentTotal.toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      })}`}
                </option>
              ))}
            </FormSelect>
          )}
        </Stack>
        <Stack
          w="full"
          direction={['column', 'row']}
          align="center"
          justify="space-between"
          py={4}
          spacing={4}
        >
          <AcceptedCards />
          <Button
            w={{ base: 'full', md: 'unset' }}
            variant="pophausOutline"
            type="submit"
            isLoading={loading}
          >
            FINALIZAR COMPRA
          </Button>
        </Stack>
      </Stack>
    </Form>
  );
}
